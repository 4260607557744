<script setup>
import { ref, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useTenantStore } from '@/stores/tenant';
import { useApiStore } from './stores/api';
import { useRouter } from 'vue-router';
import Bugsnag from '@bugsnag/js';

const authStore = useAuthStore();
const tenantStore = useTenantStore();
const apiStore = useApiStore();
const router = useRouter();

const loading = ref(true);

tenantStore
    .setTenant()
    .then((resp) => {
        Bugsnag.addMetadata('tenant', {
            id: tenantStore.tenant.id,
            name: tenantStore.tenant.name,
        });

        if (resp.user) {
            authStore.changeLocalSetting('language', navigator.language);
            Bugsnag.setUser(authStore.user.id, authStore.user.email, authStore.user.name);
            apiStore.setToken(authStore.token);
        }

        loading.value = false;
    })
    .catch(() => {
        router.push({ name: 'error' });
    });

watch(
    () => authStore.localUserSettings.darkMode,
    (newVal) => {
        if (newVal) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    },
    {
        immediate: true,
    }
);
</script>

<template>
    <Loading v-if="loading" />
    <RouterView
        v-else
        :key="$route.fullPath"
    />
</template>
