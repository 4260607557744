import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import { useApiStore } from './api';

export const useTenantStore = defineStore(
    'tenant',
    () => {
        const authStore = useAuthStore();
        const apiStore = useApiStore();

        const tenant = ref({
            name: 'Nucleus',
            settings: {
                currency: 'GBP',
                design: {
                    favicon: {
                        dark: 'https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/favicon.svg',
                        light: '"https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/favicon.svg"',
                    },
                    logo_square: {
                        dark: 'https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/favicon.svg',
                        light: 'https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/favicon.svg',
                    },
                    logo_full: {
                        dark: 'https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/logo-dark.svg',
                        light: 'https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/logo.svg',
                    },
                    colors: {
                        primary: '#2674BA',
                        newprimary: '#2868e6',
                    },
                    font: 'Inter',
                    title: 'Nucleus',
                    welcome_image:
                        'https://nucleusdevbucket.s3.eu-west-2.amazonaws.com/V2/welcome.jpg',
                    welcome_text: '<p>The Future of<br>Teaching &amp; Learning</p>',
                },
                self_registration: false,
            },
        });
        const languages = ref([
            {
                name: 'English',
                code: 'en',
            },
        ]);
        const stripe = ref(null);
        const countries = ref([]);

        const getTenantLogoLarge = computed(() => {
            if (authStore.localUserSettings.darkMode) {
                return tenant.value.settings.design.logo_full.dark;
            }

            return tenant.value.settings.design.logo_full.light;
        });

        const getTenantLogoSmall = computed(() => {
            if (authStore.localUserSettings.darkMode) {
                return tenant.value.settings.design.logo_square.dark;
            }

            return tenant.value.settings.design.logo_square.light;
        });

        const setTenant = () => {
            if (authStore.token) {
                apiStore.setToken(authStore.token);
            }

            return apiStore.api
                .get('/tenant-info')
                .json((json) => {
                    Object.assign(tenant.value, json.tenant);
                    document.documentElement.style.setProperty(
                        '--primary',
                        tenant.value.settings.design.colors.primary
                    );
                    const googleFontLink = document.createElement('link');

                    googleFontLink.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(tenant.value.settings.design.font)}:wght@400;500;600;700`;
                    googleFontLink.rel = 'stylesheet';
                    document.head.appendChild(googleFontLink);
                    document.documentElement.style.setProperty(
                        '--font',
                        tenant.value.settings.design.font
                    );

                    const link = document.createElement('link');

                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = tenant.value.settings.design.favicon.light;
                    document.getElementsByTagName('head')[0].appendChild(link);
                    stripe.value = json.stripeOnboarding;

                    if (json.user) {
                        authStore.setUser(json.user);
                    } else {
                        authStore.setUser(null);
                    }

                    return json;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        };

        const getCountriesList = () => {
            return apiStore.api
                .get('/filters/countries')
                .json((json) => {
                    countries.value = json;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        };

        return {
            tenant,
            languages,
            stripe,
            countries,
            getTenantLogoLarge,
            getTenantLogoSmall,
            setTenant,
            getCountriesList,
        };
    },
    {
        persist: true,
    }
);
